<template>
  <div>
    <div class="cardSubheader mt-1 mb-2">
      Период доступа к аккаунту
    </div>
    <v-menu
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatDates1"
          dense
          outlined
          label="Даты"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <vc-date-picker
        :value="accountDates"
        :is-range="true"
        :columns="2"
        mode="date"
        locale="ru-RU"
        @input="(e) => saveDates(e)"
      ></vc-date-picker>
    </v-menu>
    <div class="cardSubheader mt-1 mb-6">
      Периоды доступа к данным
    </div>
    <v-row v-for="period in $store.getters.getClientPeriods" :key="period.id" class="mt-n7">
      <v-col cols="11">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDates(period)"
              dense
              outlined
              label="Даты"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <vc-date-picker
            :value="period"
            :is-range="true"
            :columns="2"
            mode="date"
            locale="ru-RU"
            @input="(e) => updateClientPeriod(period, e)"
          ></vc-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-icon
          color="rgba(0,0,0,.54)"
          class="mt-2"
          style="cursor: pointer"
          @click="deleteClientPeriod(period.line_id)"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
    <v-btn color="primary" @click="addClientPeriod">
      <v-icon class="ml-n2 pl-0">mdi-plus</v-icon><span class="ml-1">Период</span>
    </v-btn>
    <v-divider class="mt-4 mb-4" />
    <div class="cardSubheader mt-1 mb-6">
      Доступные телеканалы
    </div>
    <v-row class="mt-n8 mb-2">
      <v-col cols="12">
        <v-text-field
          :value="$store.getters.getChannelsFilter"
          placeholder="Фильтр телеканалов"
          append-icon="mdi-close"
          @input="(e) => updateChannelsFilter(e)"
          @click:append="updateChannelsFilter('')"
        />
      </v-col>
    </v-row>
    <v-row v-for="channel in $store.getters.getClientChannels" :key="channel.id" class="mt-n7">
      <v-col cols="5">
        <v-autocomplete
          v-model="channel.id"
          :items="$store.getters.getAdminChannelsList"
          item-text="name"
          item-value="id"
          label="Телеканал"
          dense
          outlined
          persistent-hint
          @change="updateClientChannel(channel)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDates(channel)"
              dense
              outlined
              label="Даты"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <vc-date-picker
            :value="channel"
            :is-range="true"
            :columns="1"
            mode="date"
            locale="ru-RU"
            @input="(e) => updateClientChannel(channel, e)"
          ></vc-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-icon
          color="rgba(0,0,0,.54)"
          class="mt-2"
          style="cursor: pointer"
          @click="deleteClientChannel(channel.line_id)"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
    <v-btn color="primary" @click="addClientChannel">
      <v-icon class="ml-n2 pl-0">mdi-plus</v-icon><span class="ml-1">Телеканал</span>
    </v-btn>
    <v-divider class="mt-4 mb-4" />
    <div class="cardSubheader mt-1 mb-6 mt-5">
      География
    </div>
    <v-row class="mt-n8 mb-2">
      <v-col cols="12">
        <v-text-field
          placeholder="Фильтр городов"
          :value="$store.getters.getCitiesFilter"
          append-icon="mdi-close"
          @input="(e) => updateCitiesFilter(e)"
          @click:append="updateCitiesFilter('')"
        />
      </v-col>
    </v-row>
    <v-row
      v-for="city in $store.getters.getClientCities"
      :key="city.line_id"
      class="mt-n7"
    >
      <v-col cols="5">
        <v-autocomplete
          v-model="city.id"
          :items="$store.getters.getAdminCitiesList"
          item-text="name"
          item-value="id"
          label="Город"
          dense
          outlined
          persistent-hint
          @change="updateClientCity(city)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDates(city)"
              dense
              outlined
              label="Даты"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <vc-date-picker
            :value="city"
            :is-range="true"
            :columns="1"
            mode="date"
            locale="ru-RU"
            @input="(e) => updateClientCity(city, e)"
          ></vc-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-icon
          color="rgba(0,0,0,.54)"
          class="mt-2"
          style="cursor: pointer"
          @click="deleteClientCity(city.line_id)"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
    <v-btn color="primary" @click="addClientCity">
      <v-icon class="ml-n2 pl-0">mdi-plus</v-icon><span class="ml-1">Город</span>
    </v-btn>
  </div>
</template>

<style scoped>

.cardSubheader {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.58);
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
//import Utils from "../services/utils";

export default {
  emits: ["changeTab"],
  props: {
    selectedUser: {
      type: Object,
      default: () => {
        {return {
          login: "",
          name: "",
        }};
      },
    },
  },
  mounted() {
    this.user = this.selectedUser;
    this.accountDates.start = this.selectedUser.minActive;
    this.accountDates.end = this.selectedUser.maxActive;
  },
  data: () => ({
    user: null,
    accountDates: {},
    citiesFilter: null,
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    formatDates1() {
      //console.log(this.selectedUser)
      const sdate = this.selectedUser.minActive;
      const edate = this.selectedUser.maxActive;
      return moment(sdate).format("DD.MM.YYYY")+ " - "+ moment(edate).format("DD.MM.YYYY")
    },
  },
  methods: {
    ...mapActions(["GetUsers", "SaveUser", "AddUser", "KickUser", "SaveUserCity", "SaveUserChannel", "SaveUserPeriod"]),
    updateCitiesFilter(str) {
      this.$store.commit("setCitiesFilter", { str });
    },
    updateChannelsFilter(str) {
      this.$store.commit("setChannelsFilter", { str });
    },
    addClientPeriod() {
      this.$store.commit("addClientPeriod");
    },
    addClientCity() {
      this.$store.commit("addClientCity");
    },
    addClientChannel() {
      this.$store.commit("addClientChannel");
    },
    async deleteClientPeriod(line_id) {
      if (line_id > 0) {
        const period = {
          id: null,
          line_id,
        };
        await this.SaveUserPeriod({ user_id: this.selectedUser.id, period });
      }
      this.$store.commit("deleteClientPeriod", {line_id});
    },
    async deleteClientCity(line_id) {
      if (line_id > 0) {
        const city = {
          id: null,
          name: null,
          line_id,
        };
        await this.SaveUserCity({ user_id: this.selectedUser.id, city });
      }
      this.$store.commit("deleteClientCity", {line_id});
    },
    async deleteClientChannel(line_id) {
      if (line_id > 0) {
        const channel = {
          id: null,
          name: null,
          line_id,
        };
        await this.SaveUserChannel({ user_id: this.selectedUser.id, channel });
      }
      this.$store.commit("deleteClientChannel", {line_id});
    },
    async updateClientPeriod(period, e) {
      if (e) {
        period.start = moment(e.start).format("YYYY-MM-DD");
        period.end = moment(e.end).format("YYYY-MM-DD");
        period.id = 1;// bulk
      }
      await this.SaveUserPeriod({ user_id: this.selectedUser.id, period });
    },
    async updateClientCity(city, e) {
      if (e) {
        city.start = moment(e.start).format("YYYY-MM-DD");
        city.end = moment(e.end).format("YYYY-MM-DD");
      }
      await this.SaveUserCity({ user_id: this.selectedUser.id, city });
    },
    async updateClientChannel(channel, e) {
      if (e) {
        channel.start = moment(e.start).format("YYYY-MM-DD");
        channel.end = moment(e.end).format("YYYY-MM-DD");
      }
      await this.SaveUserChannel({ user_id: this.selectedUser.id, channel });
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
    formatDates(dates) {
      //console.log("format", dates)
      if (dates?.start && dates?.end) {
        return `${moment(dates.start).format("DD.MM.YYYY")} - ${moment(
          dates.end
        ).format("DD.MM.YYYY")}`;
      }
      return "";
    },
    async saveDates(e) {
      this.accountDates.start = moment(e.start).format("YYYY-MM-DD")+" 00:00:00";
      this.accountDates.end = moment(e.end).format("YYYY-MM-DD")+" 23:59:59";
      //console.log("save", start, end);
      await this.SaveUser({ id: this.selectedUser.id, minActive: this.accountDates.start});
      await this.SaveUser({ id: this.selectedUser.id, maxActive: this.accountDates.end});
    },
    async updateField(userId, field, value) {
      console.log(userId, field, value);
      this.$store.commit("updateUser", {userId, [field]: value});
      //this.editField(userId, null); //reset editing
    },
    async updateUser(userId, field, value) {
      if (field=="maxActive") {
        value = moment(value).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (field=="minActive") {
        value = moment(value).format("YYYY-MM-DD") + " 00:00:00";
      }
      this.$store.commit("updateUser", {userId, [field]: value});
      await this.SaveUser({ id: userId, [field]: value});
     // this.editField(userId, null); //reset editing
    },
    async toggelUserActive(userId) {
      const user = this.$store.getters.getUsers.find(
        (item) => item.id == userId
      );
      const active = this.$store.getters.getUser(userId).active;
      await this.SaveUser({ id: user.id, active });
      //await this.GetUsers();
    },
  },
  watch: {
    selectedUser: function (value) {
      this.user = value;
      this.accountDates.start = this.user.minActive;
      this.accountDates.end = this.user.maxActive;
      console.log(this.accountDates);
    },
  },
};
</script>
